import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  MAIN: {
    VERSION: 'Version',
  },
  LOADING: {
    ALMOST_THERE: "We're almost there...",
    LOADING: 'Loading',
  },
  COUNTRY_SELECTOR: {
    VENDO_TOOLS_TITLE: 'Vendo by BEES',
    NO_COUNTRY_DISCLAIMER: 'No coutry to select',
  },
  SIDEBAR: {
    HOME_TOOLS: 'Home',
    TRANSACTION: 'Transaction History',
    BALANCE: 'Balance',
    ACCESS_MANAGEMENT: 'Access Management',
  },
  HOME_TOOLS: {
    TITLE: 'Vendo Tools',
    TRANSACTION_TITLE: 'Transaction History',
    TRANSACTION_TEXT: 'Transaction history and details about BEES Vendo accounts.',
    BALANCE_TITLE: 'Balance',
    BALANCE_TEXT: 'Update balance for a specific account.',
    ACCESS_MANAGEMENT_TITLE: 'Access Management',
    ACCESS_MANAGEMENT_TEXT: 'Update user role on Vendo user',
  },
  TRANSACTION_HISTORY_LIST: {
    TITLE: 'Transaction history',
    SEGMENTED_CONTROL: {
      POC_TRANSACTION_OPTION_LABEL: 'POC transactions',
      AGENTS_TRANSACTIONS_OPTION_LABEL: 'Agents transactions',
    },
    SEARCH_FIELD_PLACEHOLDER: 'Search by transaction ID or order ID',
    EMPTY_TABLE: 'No existing transactions yet.',
    FILTER: {
      TRANSACTION_TYPE_MENU_LABEL: 'Transaction types',
      AMOUNT_MENU_LABEL: 'Amount',
      DATE_MENU_LABEL: 'Date',
    },
    TRANSACTION_HISTORY_TABLE: {
      TRANSACTION_TYPE: 'Transaction type',
      TRANSACTION_ID: 'Transaction ID',
      ORDER_ID: 'Order ID',
      AMOUNT: 'Amount',
      DATE: 'Date',
      ACTIONS: 'Actions',
    },
  },
  USER_LIST: {
    TITLE: 'User access',
    ITEM_COUNT: '({count} users)',
    USER_ACCESS: 'User access',
    NAME: 'Name',
    USER_ID: 'User ID',
    LAST_UPDATE: 'Last update',
    ACTIONS: 'Actions',
    EMPTY_TABLE: 'No existing users yet.',
    INVALID_ACCOUNT_ID: 'The account id {accountId} does not exist or won`t has any user',
    POC_INFO: {
      POC_MODEL: 'POC Model',
      PRE_COMMISSION: 'Pre commissioned',
      POS_COMMISSION: 'Pos commissioned',
    },
    MANAGE_BALANCE: {
      MODAL_TITLE: 'Managing POC’s balance',
      CURRENT_BALANCE: 'Current balance: ',
      PRE_COMMISSIONED: 'Pre commissioned: ',
      POS_COMMISSIONED: 'Pos commissioned: ',

      TRANSACTION_TYPE_LABEL: 'Transaction type',
      TRANSACTION_TYPE_HINT: `Select if you will add or remove money from the POC's balance.`,
      TRANSACTION_TYPE_PLACEHOLDER: 'Select an option',
      TRANSACTION_TYPE_SELECT_ADD: 'Add',
      TRANSACTION_TYPE_SELECT_REMOVE: 'Remove',

      AMOUNT_LABEL: 'Amount',
      AMOUNT_HINT: 'Enter the amount that will affect the balance.',
      AMOUNT_ERROR: `The amount exceeds {excededAmount} of the current balance.`,

      REASON_LABEL: 'Reason',
      REASON_HINT: 'Select the reason for the balance change.',
      REASON_FRAUD_RADIO_LABEL: 'Fraud reported',
      REASON_CLUB_B_REDEMPTION: 'Club B Redemption',
      REASON_MISSING_CREDIT_RADIO_LABEL: 'Missing credit',
      REASON_INCOMPLETE_PURCHASE_RADIO_LABEL: 'Incomplete purchase',
      REASON_INFO_CARD_TITLE: 'Reasons',
      REASON_INFO_CARD_FRAUD_TITLE: 'Fraud reported',
      REASON_INFO_CARD_FRAUD_DESC: `Fraudulent recharges on a POC’s account for transactions they don’t recognize or didn’t
          make themselves.`,
      REASON_INFO_CARD_MISSING_CREDIT_TITLE: 'Missing credit',
      REASON_INFO_CARD_MISSING_CREDIT_DESC: `The POC made a credit purchase and didn’t receive it.`,
      REASON_INFO_CARD_INCOMPLE_PURCHASE_TITLE: 'Incomplete purchase',
      REASON_INFO_CARD_INCOMPLETE_PURCHASE_DESC: `The POC didn’t complete an order and still received credit. The balance needs a
          withdrawal.`,

      RELATED_TRANSACTION_LABEL: 'Related Order ID',
      RELATED_TRANSACTION_PLACEHOLDER: 'ID',
      RELATED_TRANSACTION_HINT: 'Enter the ID of the order that is related to this balance change.',
      RELATED_TRANSACTION_ERROR: 'A valid order ID is required.',

      TICKET_URL_LABEL: 'Support ticket URL',
      TICKET_URL_PLACEHOLDER: 'URL',

      SAVE_CHANGES_SUCCESS: `The POC’s details were successfully updated.`,
      SAVE_CHANGES_ERROR: `Unable to update the POC’s details. Try again later.`,
      SAVE_CHANGES_ERROR_PRE_COMMISSION: `It is not possible to change the balance for POCs that only have pos-commissioned credits.`,
    },
    SEGMENTED_CONTROL: {
      USER_OPTION_LABEL: 'Users',
      HISTORY_LOG_OPTION_LABEL: 'History log',
    },
    HISTORY_LOG_TABLE: {
      TRANSACTION_TYPE_HEADER: 'Transaction type',
      TRANSACTION_TYPE_LABEL_USER_UPDATED: 'User role updated',
      ROLE_TRANSACTION_ID_HEADER: 'Transaction ID',
      CONTACT_HEADER: 'Contact',
      USER_ID_HEADER: 'User ID',
      PREVIOUS_ACCESS_HEADER: 'Previous access',
      NEW_ACCESS_HEADER: 'New access',
      DATE_HEADER: 'Date',
      EDITED_BY_HEADER: 'Edited by',
      ACTIONS_HEADER: 'Actions',
      ACTIONS_LABEL: 'Show',
      ACTIONS_MODAL_MAIN_TITLE: 'User access details',
      ACTIONS_MODAL_TITLE: 'User role update',
      ACTIONS_MODAL_SUBTITLE: 'Transaction ID: {accountId}',
      ACTIONS_MODAL_USER_ID: 'User ID',
      ACTIONS_MODAL_POC_ID: 'POC ID',
      ACTIONS_MODAL_PREVIOUS_ACCESS: 'Previous access',
      ACTIONS_MODAL_NEW_ACCESS: 'New access',
      ACTIONS_MODAL_EDITED_BY: 'Edited by',
      ACTIONS_MODAL_DATE_AND_TIME: 'Date and time',
      EMPTY_STATE_MESSAGE: 'No existing logs yet.',
      TOAST_SERVER_ERROR_HISTORY_LOG: `Unable to get the POC's history log. Try again later.`,
    },
  },
  VENDO_FILTER: {
    AMOUNT_RANGE_TITLE: 'Amount range',
    START_DATE_INPUT_LABEL: 'Start date',
    END_DATE_INPUT_LABEL: 'End date',
    CLEAR_ALL_BTN: 'Clear all',
  },
  BREADCRUMB: {
    ACCOUNT_SELECT: 'Account Selection',
  },
  ACCOUNT_SELECTION: {
    PAGE_TITLE: 'Vendo by BEES',
    TITLE: 'Account Selection',
    SUB_TITLE: 'Insert an Account to view its BEES Vendo Users',
    SUB_TITLE_TRANSACTIONS: 'Insert an Account to view its transactions',
    PLACEHOLDER: 'Insert Account ID here...',
    FIND_BUTTON_LABEL: 'List Users',
    FIND_BUTTON_TRANSACTIONS_LABEL: 'List Transactions',
    ACCOUNT_NOT_FOUND: 'Account not found',
    GET_ACCOUNT_API_ERROR: `Unable to validate the POC. Try again later.`,
  },
  PAGINATION: {
    ALL: 'All',
    FIRST: 'First page',
    LAST: 'Last page',
    NEXT: 'Next page',
    PREVIOUS: 'Previous page',
    ROWS_PER_PAGE: 'Rows per page:',
    PAGE: 'Page {page}',
  },
  CONSTS: {
    GO_BACK: 'Go back',
    RESULTS_FOUND: '{quantity} results found',
    RESULT_FOUND: '{quantity} result found',
    NOT_AVAILABLE: 'Not Available',
    NO_RESULTS_FOUND: 'No results found for this search.',
    BEE_NOT_FOUND: 'There’s nothing here.',
    SEARCH: 'Search',
    HOME_TOOLS: 'Home',
    DISABLED: 'Disabled',
    ACCOUNT_LABEL: 'Account',
    ACCOUNT_ID_LABEL: 'Account ID',
    USER_ID_LABEL: 'User ID',
    POC_ID_LABEL: 'POC ID',
    CONTACT_LABEL: 'Contact',
    YES: 'Yes',
    NO: 'No',
    EDIT: 'Edit',
    SAVE: 'Save',
    SHOW: 'Show',
    CANCEL: 'Cancel',
    APPLY: 'Apply',
    SAVE_CHANGES: 'Save changes',
    ACCESS_TYPES: 'Access types',
    PHONE_NUMBER: 'Phone number',
    TYPE: 'Type',
    CANCELED: 'Canceled',
    COMPLETED: 'Completed',
    STATUS: 'Status',
    CARRIER: 'Carrier',
    ID: 'ID',
    REASON: 'Reason',
    SUP_TICKET_URL: 'Support ticket URL',
    OPERATION_TYPE: 'Operation type',
  },
  USER_ROLES: {
    ADMIN: 'Admin',
    ADMIN_DESCRIPTION:
      'Can manage user access, sell digital products and check payment history on the Vendo app.',
    EMPLOYEE: 'Employee',
    EMPLOYEE_DESCRIPTION: 'Can sell digital products and check payment history on the Vendo app.',
    UNAUTHORIZED: 'Unauthorized',
    UNAUTHORIZED_DESCRIPTION: 'Cannot access the account without admin approval.',
    UNDEFINED: 'Undefined',
    UNDEFINED_DESCRIPTION: 'Cannot access the account without admin approval.',
    TOAST_SUCCESS: `The POC's details were successfully updated.`,
    TOAST_ERROR: `Unable to update the POC's details. Try again later.`,
    TOAST_ERROR_DUPLICATED_ADMIN: `Unable to update the POC’s details. The POC can only have 1 admin per account.`,
  },
  MODAL: {
    EDIT_USER_TITLE: 'Edit user access',
    EDIT_USER_TEXT: 'User access',
    WARNING_ONLY_ONE_ADMIN_PER_ACCOUNT:
      'Ensure an admin is assigned to this account before changing this user’s access. The POC must have 1 admin per account.',
  },
  APPLICATION_403_ERROR: {
    HEADER: 'Hmm... Access denied!',
    DESCRIPTION: "It looks like this page wasn't found or doesn't exist.",
    BUTTON_LABEL: 'HOME',
  },
  SHARED_BUTTONS: {
    MANAGE_BALANCE_TITLE: 'Manage balance',
  },
  TRANSACTIONS_TYPE: {
    BONUS: 'Bonus',
    BONUS_COMMISSION: 'Bonus with commission',
    RECHARGE: 'Recharge sold',
    EXTRA_FEE: 'Service fee',
    MANUAL_DEBIT: 'BEES credit reversal',
    MANUAL_CREDIT: 'BEES credit correction',
    MANUAL_DEBIT_COMMISSION: 'BEES credit reversal with pre-commission',
    MANUAL_CREDIT_COMMISSION: 'BEES credit correction with pre-commission',
    UTILITY: 'Utility payment',
    BILL_PAYMENT: 'Bill payment',
    CREDIT_BOUGHT: 'BEES credit bought',
    CREDIT_BOUGHT_COMMISSION: 'BEES credit bought with commission',
    REVERSED_TRANSACTION: `Refund of {referenceId}`,
    COMMISSION: 'Commission earned',
    GIFT_CARD: 'Gift Card sold',
    GAMBLING: 'Gambling sold',
    WALLET: 'Wallet sold',
    CREDIT_BOUGHT_NEQUI_PUSH: 'Money Add - Nequi',
    CREDIT_BOUGHT_DAVIPLATA: 'Money Add - DaviPlata',
    CREDIT_BOUGHT_CASH_DEPOSIT: 'Money Add - Cash Deposit',
    CREDIT_BOUGHT_BANCOLOMBIA_QR: 'Money Add - Bancolombia QR',
  },
  INFO_MODEL: {
    TITLE: 'Transaction details',
    CUSTOMER_TOTAL_PAID: 'Customer total paid',
    CUSTOMER_PAYMENT_INFO: 'Customer payment information',
    BILL_AMOUNT: 'Bill amount',
    BEES_SERVICE_FEE: 'Bees service fee',
    CUSTOMER_FEE: 'Customer fee',
    CANCELED_TRANSACTION: 'Canceled transaction',
    RELATED_TRANSACTION: 'Related transaction',
    RELATED_TRANSACTION_ID: 'Related transaction id',
    GIFT_CARD_CODE: 'Gift card code',
  },
  OPERATION_TYPE: {
    CREDIT: 'Credit',
    DEBIT: 'Debit',
  },
  CARRIER_TYPE: {
    DATA_PACKAGE: 'Prepaid Minutes and Data',
    AIRTIME: 'Airtime',
    DATA_ONLY: 'Data Packages',
    PREPAID: 'Prepaid plan - Data only',
  },
};

export default enUS;
